import React from "react";

import Layout from "../components/layout";

export default function NotFoundPage() {
  return (
    <Layout title="Not Found">
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  );
}
